import React from "react";
import { useMutation } from "react-query";
import { useTheme } from "./theme";
import { getCurrentAddress } from "../api/location";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    useDisclosure,
    Text
} from "@chakra-ui/react";

const defaultLocationValue = {
    latitude: null,
    longitude: null,
    allowedGeolocation: false,
    error: null,
    isLoading: true,
    forcedLocation: null,
    actualLocation: {
        street: null,
        city: null,
        state: null,
        country: null,
        neighborhood: null,
        formatted_address: "Sem Localização"
    },
    forceLocation: () => {},
    hasGeolocation: () => {},
    getLocationObject: () => {}
};

const GeolocationContext = React.createContext(defaultLocationValue);

export function GeolocationProvider({ children }) {
    const { localModule, primaryColor, isLoading } = useTheme();
    const [geolocation, setGeolocation] = React.useState(defaultLocationValue);
    const { isOpen, onOpen, onClose } = useDisclosure();

    React.useEffect(() => {
        if (!localStorage.getItem("geolocation_permission")) {
            setTimeout(() => {
                onOpen();
            }, 3000);
        } else {
            requestGeolocation();
        }
    }, []);

    const actualLocationMutation = useMutation(
        ({ longitude, latitude }) => getCurrentAddress(latitude, longitude),
        {
            onSuccess(data) {
                setGeolocation((prev) => ({
                    ...prev,
                    isLoading: false,
                    actualLocation: data
                }));
            },
            onError(error) {
                console.error(error);
            }
        }
    );

    const requestGeolocation = () => {
        localStorage.setItem("geolocation_permission", "true");
        onClose();

        if (!navigator.geolocation) {
            console.log("Geolocalização não é suportada pelo seu navegador.");
            return;
        }

        const success = (position) => {
            console.log("success", position);
            const { latitude, longitude } = position.coords;
            setGeolocation((prev) => {
                const newGeolocation = {
                    ...prev,
                    latitude,
                    longitude,
                    allowedGeolocation: true,
                    isLoading: true
                };
                localStorage.setItem(
                    "geolocation",
                    JSON.stringify(newGeolocation)
                );
                return newGeolocation;
            });

            actualLocationMutation.mutateAsync({ latitude, longitude });
        };

        const errorHandler = (err) => {
            console.log("err", err);
            setGeolocation((prev) => {
                const newGeolocation = {
                    ...prev,
                    latitude: null,
                    longitude: null,
                    allowedGeolocation: false,
                    isLoading: false,
                    error: err
                };
                localStorage.setItem(
                    "geolocation",
                    JSON.stringify(newGeolocation)
                );
                return newGeolocation;
            });
        };

        navigator.geolocation.getCurrentPosition(success, errorHandler);
    };

    if (isLoading) return null;

    return (
        <GeolocationContext.Provider
            value={{
                ...geolocation,
                forceLocation: (location) =>
                    setGeolocation((prev) => ({
                        ...prev,
                        forcedLocation: location
                    })),
                getLocationObject: () => {
                    if (geolocation.forcedLocation)
                        return geolocation.forcedLocation;
                    return {
                        latitude: geolocation.latitude,
                        longitude: geolocation.longitude
                    };
                },
                hasGeolocation: () =>
                    geolocation.forcedLocation || geolocation.allowedGeolocation
            }}
        >
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Permissão de Localização</ModalHeader>
                    <ModalBody>
                        <Text>
                            Para melhorar sua experiência, e mostrar promoções
                            perto de você, precisamos acessar sua localização.
                            Você pode permitir isso agora?
                        </Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            colorScheme="blue"
                            mr={3}
                            backgroundColor={primaryColor}
                            _hover={{ backgroundColor: primaryColor }}
                            onClick={requestGeolocation}
                        >
                            Continuar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {children}
        </GeolocationContext.Provider>
    );
}

export function useGeolocation() {
    return React.useContext(GeolocationContext);
}
