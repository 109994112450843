// TODO: Legacy page, modernize this

import React, { useState } from "react";
import server from "../server";
import ReactInputMask from "react-input-mask";
import { useSettings, useTheme } from "../context/index";
import {
    Box,
    Button,
    Link,
    Select,
    Text,
    Textarea,
    VStack
} from "@chakra-ui/react";
import { Title } from "../components/atoms/Title";
import { axiosInstance } from "../axios";
import { BannerPageLayout } from "../templates/BannerPageLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faWhatsapp,
    faWhatsappSquare
} from "@fortawesome/free-brands-svg-icons";
import { APP_EVENTS, spawnAppEvent } from "../utils/app";
import { ExternalLink } from "../components/atoms/ExternalLink";
import { TopMenu } from "../components/organisms/TopMenu";
import { verificateCPF } from "../utils";

const Input = (props) => (
    <VStack width="100%" spacing={3} alignItems="flex-start">
        <Title>{props.name}</Title>
        <ReactInputMask
            style={{
                backgroundColor: "#FAFAFA",
                width: "100%",
                padding: "1rem",
                border: "1px solid rgb(226, 232, 240)",
                borderRadius: "6px",
                height: "40px",
                ...props.style
            }}
            value={props.value}
            onChange={(e) => {
                props.onChange(e);
            }}
            name={props.name}
            mask={props.mask}
            placeholder={`Ex.: ${props.placeholder}`}
        />
    </VStack>
);

const Contact = (props) => {
    let [name, setName] = useState(server.user ? server.user.full_name : "");
    let [phone, setPhone] = useState("");
    let [cpf, setCpf] = useState("");
    let [email, setEmail] = useState(server.user ? server.user.email : "");
    let [about, setAbout] = useState("Dúvidas");
    let [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    let [sent, setSend] = useState(false);
    const {
        primaryColor,
        primaryFontColor,
        cellphone,
        name: agreementName
    } = useTheme();
    const { isApp } = useSettings();

    let [errors, setErrors] = useState([]);
    let errorStyle = { border: "1px solid red" };

    const onSend = () => {
        setLoading(true);
        let errors = [];

        console.log(phone);
        if (name === "") errors.push("name");
        if (email === "" || email.indexOf("@") === -1) errors.push("email");
        if (about === "") errors.push("about");
        if (message === "") errors.push("message");
        if (phone?.replace(/[^a-zA-Z0-9]/g, "")?.length < 11)
            errors.push("phone");
        if (!verificateCPF(cpf?.replace(/[^a-zA-Z0-9]/g, "")))
            errors.push("cpf");

        if (errors.length > 0) {
            setLoading(false);
            return setErrors(errors);
        }

        let payload = {
            name,
            email,
            subject: about,
            message,
            cpf,
            cellphone: phone,
            agreement: agreementName,
            landing: "Landing " + agreementName,
            origin: "landing"
        };

        axiosInstance
            .post("/contact/", payload)
            .then(() => {
                setSend(true);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    };
    return (
        <BannerPageLayout title="Fale Conosco" paddingBottom={"64px"}>
            <TopMenu />
            {!sent ? (
                <VStack width="100%" spacing={6}>
                    <Input
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value);
                            setErrors([""]);
                        }}
                        name="Nome"
                        placeholder="Fulano Costa"
                        style={errors.indexOf("name") !== -1 ? errorStyle : {}}
                    />
                    <Input
                        value={phone}
                        onChange={(e) => {
                            setPhone(e.target.value);
                            setErrors([]);
                        }}
                        name="Celular"
                        placeholder="(xx) xxxx-xxxxx"
                        mask="(99) 9 9999-9999"
                        style={errors.indexOf("phone") !== -1 ? errorStyle : {}}
                    />
                    <Input
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setErrors([]);
                        }}
                        name="Email"
                        placeholder="fulano@gmail.com"
                        style={errors.indexOf("email") !== -1 ? errorStyle : {}}
                    />
                    <Input
                        value={cpf}
                        onChange={(e) => {
                            setCpf(e.target.value);
                            setErrors([]);
                        }}
                        name="CPF"
                        placeholder="xxx.xxx.xxx-xx"
                        mask="999.999.999-99"
                        style={errors.indexOf("cpf") !== -1 ? errorStyle : {}}
                    />
                    <VStack width="100%" spacing={3} alignItems="flex-start">
                        <Title>Assunto</Title>
                        <Select
                            value={about}
                            onChange={(e) => {
                                setAbout(e.target.value);
                                setErrors([]);
                            }}
                            name="Assunto"
                            backgroundColor="#FAFAFA"
                        >
                            <option value="Dúvidas">Dúvidas</option>
                            <option value="Quero cadastrar meu estabelecimento">
                                Quero cadastrar meu estabelecimento
                            </option>
                            <option value="Quero oferecer o clube para meus clientes/funcionarios/associados">
                                Quero oferecer o clube para meus
                                clientes/funcionarios/associados
                            </option>
                            <option value="Quero excluir minha conta e meus dados">
                                Quero excluir minha conta e meus dados
                            </option>
                            <option value="Reclamação">Reclamação</option>
                        </Select>
                    </VStack>
                    <Textarea
                        value={message}
                        onChange={(e) => {
                            setMessage(e.target.value);
                            setErrors([]);
                        }}
                        name="Mensagem"
                        placeholder="Quero indicar o estabelecimento...; Quero contratar o clube para minha empresa..."
                        style={{
                            ...(errors.indexOf("message") !== -1
                                ? errorStyle
                                : { minHeight: "150px" })
                        }}
                    />
                    <Button
                        isFullWidth
                        isLoading={loading}
                        onClick={() => onSend()}
                        className="fly"
                        height="45px"
                        style={{
                            backgroundColor: primaryColor
                        }}
                    >
                        <Title color={primaryFontColor}>Enviar</Title>
                    </Button>
                </VStack>
            ) : (
                <div
                    className="contact-form"
                    style={{
                        height: "65vh",
                        textAlign: "left",
                        paddingTop: 30
                    }}
                >
                    <h1>Obrigado!</h1>
                    <p>
                        Mensagem enviada com sucesso, rapidinho entraremos em
                        contato com você.
                    </p>
                </div>
            )}
            <ExternalLink
                paddingBottom="100px"
                to={`https://api.whatsapp.com/send?phone=55${
                    cellphone ? cellphone : "21974204416"
                }&text=Olá,%20gostaria%20de%20falar%20com%20a%20Partiu!`}
                transform="translateY(-10px)"
            >
                <Text
                    textAlign="center"
                    verticalAlign="middle"
                    color="green"
                    opacity="0.6"
                    fontSize="10pt"
                >
                    É uma emergência?{" "}
                    <span
                        style={{
                            textDecoration: "underline",
                            verticalAlign: "middle"
                        }}
                    >
                        Entre em contato conosco pelo WhatsApp!
                    </span>
                    <FontAwesomeIcon
                        size="2x"
                        style={{ marginLeft: "10px", verticalAlign: "middle" }}
                        icon={faWhatsappSquare}
                    />
                </Text>
            </ExternalLink>
        </BannerPageLayout>
    );
};

export default Contact;
