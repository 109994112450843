import {
    Button,
    Image,
    useToast,
    VStack,
    Flex,
    Spacer,
    Link,
    Text
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { partiuClient } from "../../axios";
import { useSettings, useTheme } from "../../context";
import { PasswordInput } from "../../components/atoms/PasswordInput";
import { Title } from "../../components/atoms/Title";
import { Input } from "../../components/atoms/Input";
import { useNavigate } from "react-router-dom";

export function PlayhubWhitelabel() {
    const { agreement, name, logo, idp, planSaleModule } = useTheme();
    const toast = useToast();
    const { isIOS } = useSettings();

    const navigate = useNavigate();

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    function redirect(token) {
        window.location.href = `/?token=${token}`;
    }
    const loginPlayhubMutation = useMutation(
        (data) =>
            partiuClient.integrations.playhub.login(
                username,
                password,
                idp
            ),
        {
            onSuccess(data) {
                redirect(data.token);
            },
            onError(error) {
                if (error.response.status === 403) {
                    toast({
                        title: "Erro",
                        description:
                            `Usuário não está habilitado à usar o convênio ${name}. Entre em contato com o suporte.`,
                        status: "error"
                    });
                } else {
                    toast({
                        title: "Erro",
                        description:
                            "Não foi possível fazer login com os dados colocados. Reveja e tente novamente",
                        status: "error"
                    });
                }
            }
        }
    );

    useEffect(() => {
        let savedData = localStorage.getItem("auto-login");

        if (!savedData) return;

        try {
            savedData = JSON.parse(savedData);
            if (savedData.slug && savedData.token)
                redirect(savedData.token);
        } catch {
            localStorage.removeItem("auto-login");
        }
    }, []);

    /* if (!thirdPartyLogin) return <></>; */

    return (
        <VStack
            forcePrimaryColor={agreement?.primaryColor}
            forceSecondaryColor={agreement?.secondaryColor}
            marginTop={isIOS ? "-35px" : "0px"}
            paddingBottom={"16px"}
            justify="center"
            align="center"
            direction="column"
            margin="auto"
            width={{ base: "100%", lg: "50%" }}
        >
            <Flex justify="center" align="center" width="100%" height="100%" paddingBottom={{ base: "64px", lg: "0" }}>
                <Flex
                    display={{ base: "none", md: "flex" }}
                    flex="1"
                    height="100%"
                    backgroundColor="#FAFAFA"
                    justify="center"
                    align="center"
                >
                    <Image
                        w="300px"
                        objectFit="contain"
                        src={agreement?.logo || logo}
                    />
                </Flex>
                <Flex
                    direction="column"
                    spacing={10}
                    justify="center"
                    width="100%"
                    align="center"
                    minHeight="100%"
                >
                    <Flex padding="25px 0px" justify="center" align="center" minH="250px" flex="1">
                        <Image
                            maxW="300px"
                            maxH="200px"
                            // w="30%"
                            objectFit="cover"
                            src={agreement?.logo || logo}
                        />
                    </Flex>
                    <VStack flex="1" width="80%">
                        <VStack
                            align="flex-start"
                            spacing={7}
                            width={{ base: "100%", lg: "60%" }}
                        >
                            <Title paddingBottom="10px">Entre utilizando seu nome de usuário e senha do provedor {name}:</Title>
                            <Input
                                value={username}
                                onChange={setUsername}
                                placeholder="Nome de usuário"
                                type="text"
                            />
                            <VStack width="100%" spacing={2}>
                                <PasswordInput
                                    pr='4.5rem'
                                    value={password}
                                    onChange={setPassword}
                                    placeholder="Senha"
                                />
                                <Link
                                    fontSize="10px"
                                    alignSelf="end"
                                    textDecoration="underline !important"
                                    margin="0px"
                                    height="48px"
                                    lineHeight={"48px"}
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        toast({
                                            title: "Recuração de senha",
                                            description:
                                                `Para recuperar a senha da sua conta, deverá entrar em contato com o seu provedor ${name}.`,
                                            status: "error"
                                        });
                                    }}
                                    target="_blank"
                                >
                                    Esqueci minha senha
                                </Link>
                            </VStack>

                            <Spacer />
                            <Button
                                width="100%"
                                isLoading={loginPlayhubMutation.isLoading}
                                onClick={loginPlayhubMutation.mutate}
                            >
                                Entrar
                            </Button>
                            {planSaleModule && (
                                <>
                                    <VStack display={"flex"} justifyContent={"center"} width="100%">
                                        <Text paddingTop={"8px"}>
                                            Não é cliente {name}?
                                        </Text>
                                        <Button href="/signup" size='sm' fontSize={11} onClick={() => navigate("/signup")}>
                                            Clique aqui e faça seu cadastro
                                        </Button>
                                    </VStack>
                                </>
                            )}
                        </VStack>
                    </VStack>
                </Flex>
            </Flex>
        </VStack>
    );
}