import { Button } from "@chakra-ui/button";
import { Image } from "@chakra-ui/image";
import { Center, Text } from "@chakra-ui/layout";
import { Modal, ModalContent, ModalOverlay, ModalBody } from "@chakra-ui/modal";
import { Progress } from "@chakra-ui/progress";
import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "../../../context/index";
import { useSettings } from "../../../context/index";
import { RateExperience } from "./RateExperienceModal";
import { APP_EVENTS, spawnAppEvent } from "../../../utils/app";

export const ActivationModal = ({
    isOpen,
    onClose,
    link,
    company,
    discount,
    initialValue
}) => {
    const btnRef = React.useRef();
    const [redirected, setRedirected] = useState("not");
    const [showRateExperience, setShowRateExperience] = useState(false);
    const { logo } = useTheme();
    const { blockNewTab = false, isApp, isMobile } = useSettings();

    useEffect(() => {
        setRedirected("not");
        setShowRateExperience(false);
    }, [isOpen]);

    useEffect(() => {
        if (isOpen && link && redirected === "not") {
            setRedirected("pending");

            // Opens link in 1.5s
            setTimeout(() => {
                setRedirected("done");

                if (isApp) {
                  spawnAppEvent(APP_EVENTS.OPEN_LINK, link);
                  setShowRateExperience(true);
                  return 
                } else {
                  window.open(link, '_blank');
                }
                // Checks if window has lost focus (user changed tab) or is App (opens automatically)
                let interval = setInterval(() => {
                    if (!document.hasFocus() || isApp) {
                        setShowRateExperience(true);
                        clearInterval(interval);
                    }
                }, 1000);
            }, 2000);
        }
    }, [link, isOpen, redirected]);

    if (showRateExperience)
        return (
            <RateExperience
                discount={discount}
                company={company}
                isOpen={isOpen}
                initialValue={initialValue}
                onClose={() => {
                    onClose();
                    setShowRateExperience(false);
                }}
            />
        );

    return (
        <Modal
            isCentered
            isOpen={isOpen}
            onClose={onClose}
            ref={btnRef}
            closeOnOverlayClick={redirected === "done"}
            id="activation-modal"
        >
            <ModalOverlay
                backgroundColor="rgba(0,0,0,0.8)"
                style={{
                    backdropFilter: "blur(5px)",
                    "-webkit-backdrop-filter": "blur(5px)"
                }}
            />
            <ModalContent width="90%">
                <ModalBody textAlign="center" padding="20px">
                    <Center>
                        <Image
                            src={logo}
                            minWidth="100px"
                            objectFit="contain"
                            minHeight="100px"
                            maxWidth="150px"
                            maxHeight={"150px"}
                        ></Image>
                    </Center>
                    <Text marginBottom="20px">
                        Seu desconto {company.name} está sendo ativado...
                    </Text>
                    <Progress size="xs" isIndeterminate></Progress>
                    {redirected === "done" && !isApp ? (
                        <a
                            style={{ marginTop: "20px" }}
                            href={link}
                            target="_blank"
                        >
                            <Button marginTop="20px">
                                A página não abriu? Clique Aqui.
                            </Button>
                        </a>
                    ) : (
                        <></>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
